import { configureStore } from '@reduxjs/toolkit';
import LogReducer from './log';

const store = configureStore({
  reducer: {
    log: LogReducer,
  },
});

export default store;
