const SERVER_URL =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_DEV_SERVER_URL
    : process.env.REACT_APP_PROD_SERVER_URL;

const FILE_URL =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_DEV_FILE_URL
    : process.env.REACT_APP_PROD_FILE_URL;

export { SERVER_URL, FILE_URL };
