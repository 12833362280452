import {
  Dialog,
  DialogContent,
  Stack,
  Typography,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FILE_URL } from '../../assets/constants';
import { ACTIONS } from '../../states/log';
import styles from './styles/Location.module.css';
import Cookies from 'universal-cookie';

export default function Venue() {
  const cookie = new Cookies();
  const { isLoggedIn, event, location } = useSelector(state => state.log);
  const dispatch = useDispatch();

  const handleLocation = data => {
    cookie.set('locationSession', data._id, {
      domain:
        process.env.NODE_ENV === 'production' ? 'cjeventhub.com' : 'localhost',
    });
    dispatch({ type: ACTIONS.ONLOCATIONCHANGE, payload: data });
  };

  return (
    <Dialog
      open={isLoggedIn && Boolean(event) && !Boolean(location)}
      fullWidth
      maxWidth='md'>
      <DialogContent>
        <Stack py={1}>
          <Typography textAlign='center' fontSize={36} mb={2}>
            Select Venue
          </Typography>
          <Grid container gridAutoRows='1fr' spacing={1}>
            {event?.venues?.length > 0
              ? event?.venues?.map(val => (
                  <Grid xs={12} md={6} key={val._id}>
                    <Card
                      className={styles.card}
                      onClick={() => handleLocation(val)}>
                      <CardHeader
                        title={<Typography variant='h4'>{val.name}</Typography>}
                      />
                      <CardMedia
                        image={`${FILE_URL}${val.image}`}
                        title={val.name}
                        sx={{ height: 240 }}
                      />
                      <CardContent>
                        <Typography variant='h5'>
                          {val.description ? val.description : 'No description'}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))
              : null}
          </Grid>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
