import { CameraRounded } from '@mui/icons-material';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import axios from 'axios';
import Compressor from 'compressorjs';
import React from 'react';
import { Camera } from 'react-camera-pro';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { dataURLtoFile } from '../../assets';
import { SERVER_URL } from '../../assets/constants';
import CustomButton from '../../components/CustomButton';
import StepsContext from '../../context/StepsContext';
import styles from './styles/Steps.module.css';

export default function Step2() {
  const { event } = useSelector(state => state.log);
  const { step, data, onInput, onPrev, onNext } =
    React.useContext(StepsContext);
  const timerRef = React.useRef(null);
  const cameraRef = React.useRef(null);
  const [start, setStart] = React.useState(false);
  const [count, setCount] = React.useState(3);
  const [shutter, setShutter] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [errors, setErrors] = React.useState(null);
  const [numberOfCameras, setNumberOfCameras] = React.useState(0);

  const startTimer = () => {
    onInput('image', null);
    setStart(true);
    setShutter(false);
    timerRef.current = setInterval(() => {
      setCount(count => count - 1);
    }, 1000);
  };

  const handleRetake = () => onInput('image', null);

  const handleNext = () => {
    setIsLoading(true);
    setErrors(null);
    const formData = new FormData();
    formData.append('image', data.image);
    axios
      .post(`${SERVER_URL}registration/step2`, formData, {
        withCredentials: true,
      })
      .then(({ data }) => {
        onNext();
        setIsLoading(false);
      })
      .catch(e => {
        if (e.response.status === 415) setErrors(e.response.data);
        if (e.response.status === 500)
          toast.error('An internal error occurred.');
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    if (count === 0) {
      clearInterval(timerRef.current);

      const image = dataURLtoFile(cameraRef.current.takePhoto());

      new Compressor(image, {
        quality: 0.5,
        success(result) {
          onInput('image', result);
        },
      });

      setCount(3);
      setStart(false);
      setShutter(true);
    }
  }, [count, onInput]);

  return (
    <Stack gap={4} px={4}>
      <Typography
        variant='h2'
        textTransform={event?.settings?.registration?.transformation}
        color={event?.settings?.registration?.color}
        fontWeight='bold'
        sx={{ typography: { md: 'h2', xs: 'h3' } }}>
        {event?.settings?.step2?.title}
      </Typography>
      <Box
        className={`${styles.cameraContainer} ${shutter && styles.shutter}`}
        style={{
          '--speed': `${event?.settings?.step2?.speed}ms`,
          color: event?.settings?.step2?.color || '#ffffff',
        }}
        data-count={start ? (count === 0 ? '' : count) : ''}
        sx={{ color: event?.settings?.registration?.color }}>
        {data.image ? (
          <img
            src={data.image && URL.createObjectURL(data.image)}
            alt='Camera screenshot'
            className={styles.screenshot}
          />
        ) : (
          step === 1 && (
            <Camera
              aspectRatio={1 / 1}
              ref={cameraRef}
              numberOfCamerasCallback={setNumberOfCameras}
            />
          )
        )}
      </Box>
      {typeof errors === 'string' && (
        <Typography variant='h4' color='error'>
          {errors}
        </Typography>
      )}
      <Stack alignItems='center' gap={2}>
        {data.image ? (
          <React.Fragment>
            <CustomButton
              isLoading={isLoading}
              onClick={handleNext}
              variant='contained'
              color='success'>
              next
            </CustomButton>
            <Button className={styles.roundBtn} onClick={handleRetake}>
              retake photo
            </Button>
          </React.Fragment>
        ) : (
          <IconButton
            onClick={startTimer}
            sx={{
              width: 'fit-content',
              alignSelf: 'center',
              color: event?.settings?.registration?.color,
            }}
            disabled={start || numberOfCameras === 0}>
            <CameraRounded sx={{ fontSize: { md: 180, xs: 120 } }} />
          </IconButton>
        )}
        <Button className={styles.roundBtn} onClick={onPrev}>
          Go back
        </Button>
      </Stack>
    </Stack>
  );
}
