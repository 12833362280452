import { LogoutRounded } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import axios from 'axios';
import React from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { SERVER_URL } from '../../assets/constants';
import { ACTIONS } from '../../states/log';
import styles from './styles/Location.module.css';

export default function Logout({ onClose }) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setIsLoading(false);
  };

  const handleSubmit = () => {
    setIsLoading(true);
    axios
      .post(`${SERVER_URL}auth/logout`, {}, { withCredentials: true })
      .then(data => {
        toast.warning('Log out successful.');
        handleClose();
        onClose();
        dispatch({ type: ACTIONS.ONLOGOUT });
      })
      .catch(e => {
        toast.error('An internal error occurred.');
        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <ListItemButton onClick={handleOpen}>
        <ListItemIcon>
          <LogoutRounded />
        </ListItemIcon>
        <ListItemText primary={<Typography variant='h4'>Log out</Typography>} />
      </ListItemButton>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth='sm'>
        <DialogTitle fontSize={36}>Log out</DialogTitle>
        <DialogContent>
          <DialogContentText fontSize={32}>
            Are you sure you want to log out?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className={styles.btn} onClick={handleClose}>
            cancel
          </Button>
          <Button
            className={styles.btn}
            onClick={handleSubmit}
            variant='contained'
            color='warning'
            disabled={isLoading}
            {...(isLoading && { endIcon: <CircularProgress size={24} /> })}>
            log out
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
