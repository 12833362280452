import { Button, CircularProgress } from '@mui/material';
import styles from './styles/Components.module.css';
import propTypes from 'prop-types';
import { useSelector } from 'react-redux';

function CustomButton({
  type = 'button',
  onClick,
  children,
  variant = 'text',
  color = 'primary',
  fullWidth,
  isLoading,
}) {
  const { event } = useSelector(state => state.log);

  return (
    <Button
      type={type}
      className={styles.roundBtn}
      onClick={onClick}
      variant={variant}
      color={color}
      disabled={isLoading}
      sx={{
        width: fullWidth ? '100%' : 'fit-content',
        background: event?.settings?.buttons?.bgcolor,
        color: event?.settings?.buttons?.color,
        '&:hover': {
          bgcolor: event?.settings?.buttons?.bgcolor,
          filter: 'brightness(120%)',
        },
      }}
      {...(isLoading && { endIcon: <CircularProgress size={24} /> })}>
      {children}
    </Button>
  );
}

CustomButton.propTypes = {
  type: propTypes.oneOf(['button', 'submit']),
  children: propTypes.string.isRequired,
  onClick: propTypes.func,
  variant: propTypes.oneOf(['contained', 'outlined', 'text']),
  color: propTypes.oneOf([
    'error',
    'info',
    'inherit',
    'primary',
    'secondary',
    'success',
    'warning',
  ]),
  fullWidth: propTypes.bool,
  isLoading: propTypes.bool,
};

export default CustomButton;
