import React from 'react';
import {
  FormGroup,
  Typography,
  Stack,
  Button,
  FormLabel,
  Box,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import ReactSignatureCanvas from 'react-signature-canvas';
import styles from './styles/Steps.module.css';
import CustomTextField from '../../components/CustomTextField';
import StepsContext from '../../context/StepsContext';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { SERVER_URL } from '../../assets/constants';
import CustomButton from '../../components/CustomButton';
import { toast } from 'react-toastify';
import { ErrorRounded } from '@mui/icons-material';
import { dataURLtoFile } from '../../assets';
import Compressor from 'compressorjs';
import { Link } from 'react-router-dom';

export default function Step1({ reset, onFinish }) {
  const { event } = useSelector(state => state.log);
  const { step, data, onInput, onSelect, onNext, onReset } =
    React.useContext(StepsContext);
  const ref = React.useRef();
  const [focused, setFocused] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [errors, setErrors] = React.useState(null);
  const [queryLoad, setQueryLoad] = React.useState(false);

  let message = event?.settings?.step1?.message;
  let array;

  if (message) {
    array = message.split('*');
  }

  const handleInput = e => {
    onInput(e.name, e.value);
    setQueryLoad(true);
    axios
      .get(`${SERVER_URL}registration?search=${e.value}`, {
        withCredentials: true,
      })
      .then(({ data }) => {
        setOptions(data);
        setQueryLoad(false);
      })
      .catch(e => setQueryLoad(false));
  };

  const handleSelect = e => {
    onSelect({
      user_id: e.user_id,
      firstname: e.firstname,
      lastname: e.lastname,
      isReckitt: !e.isDoctor,
    });
  };

  const handleSignature = e => {
    const image = dataURLtoFile(ref.current.toDataURL(), 'signature.jpg');
    new Compressor(image, {
      quality: 0.5,
      success(result) {
        onInput('signature', result);
      },
    });
  };

  const handleFocused = key => setFocused(key);

  const handleReset = () => {
    onInput('signature', null);
    ref.current.clear();
  };

  const handleTick = () => {
    onInput('isReckitt', !data.isReckitt);
  };

  const handleNext = () => {
    setIsLoading(true);
    setErrors(null);
    const formData = new FormData();
    formData.append('signature', data.signature);
    formData.append('user_id', data.user_id);
    formData.append('firstname', data.firstname);
    formData.append('lastname', data.lastname);
    axios
      .post(`${SERVER_URL}registration/step1`, formData, {
        withCredentials: true,
      })
      .then(({ data }) => {
        onNext();
        setIsLoading(false);
      })
      .catch(e => {
        if (e.response.status === 422) setErrors(e.response.data);
        if (e.response.status === 415) setErrors(e.response.data);
        if (e.response.status === 500)
          toast.error('An internal error occurred.');
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    if (reset) {
      ref.current.clear();
      onFinish(false);
      setOptions([]);
    }
  }, [reset, onFinish]);

  return (
    <Stack gap={1} px={4}>
      {event?.settings?.step1?.title && (
        <Typography
          sx={{
            typography: { md: 'h4', xs: 'h5' },
            color: event?.settings?.registration?.color,
            textTransform: event?.settings?.registration?.transformation,
          }}>
          {event?.settings?.step1?.title}
        </Typography>
      )}
      <CustomTextField
        name='user_id'
        label='PRC number'
        options={options}
        value={data.user_id}
        onInput={handleInput}
        onSelect={handleSelect}
        transformation={event?.settings?.registration?.transformation}
        focused={focused}
        onFocus={handleFocused}
        error={
          typeof errors === 'object'
            ? errors?.find(obj => obj.param === 'user_id')?.msg
            : ''
        }
        canSearch={step === 0}
        searching={queryLoad}
      />
      <CustomTextField
        name='firstname'
        label='first name'
        options={options}
        value={data.firstname}
        onInput={handleInput}
        onSelect={handleSelect}
        transformation={event?.settings?.registration?.transformation}
        focused={focused}
        onFocus={handleFocused}
        error={
          typeof errors === 'object'
            ? errors?.find(obj => obj.param === 'firstname')?.msg
            : ''
        }
        canSearch={step === 0}
        searching={queryLoad}
      />
      <CustomTextField
        name='lastname'
        label='last name'
        options={options}
        value={data.lastname}
        onInput={handleInput}
        onSelect={handleSelect}
        transformation={event?.settings?.registration?.transformation}
        focused={focused}
        onFocus={handleFocused}
        error={
          typeof errors === 'object'
            ? errors?.find(obj => obj.param === 'lastname')?.msg
            : ''
        }
        canSearch={step === 0}
        searching={queryLoad}
      />
      <FormGroup>
        <FormLabel>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            textTransform={event?.settings?.registration?.transformation}
            pb={0.5}
            gap={1}>
            <Stack direction='row'>
              <Typography
                variant='h4'
                fontWeight='bold'
                color={
                  typeof errors === 'string'
                    ? '#d32f2f'
                    : event?.settings?.registration?.color
                }
                sx={{ typography: { md: 'h4', xs: 'h6' } }}>
                Signature
              </Typography>
              {typeof errors === 'string' && (
                <ErrorRounded color='error' sx={{ fontSize: 42 }} />
              )}
            </Stack>
            <FormControlLabel
              label='Flag as reckitt employee'
              control={
                <Checkbox checked={data.isReckitt} onInput={handleTick} />
              }
              sx={{ flexDirection: 'row-reverse' }}
              componentsProps={{
                typography: { color: event?.settings?.registration?.color },
              }}
            />
          </Stack>
        </FormLabel>
        <Box position='relative'>
          <Button size='large' className={styles.btn} onClick={handleReset}>
            reset
          </Button>
          <ReactSignatureCanvas
            ref={ref}
            onEnd={handleSignature}
            canvasProps={{
              className: styles.canvas,
              ...(typeof errors === 'string' && {
                style: {
                  border: 'solid 4px #d32f2f',
                },
              }),
            }}
          />
        </Box>
        {typeof errors === 'string' && (
          <Typography
            variant='h4'
            color='error'
            textAlign='left'
            ml={1}
            fontWeight='bold'>
            {errors}
          </Typography>
        )}
      </FormGroup>
      <Typography
        variant='h5'
        textTransform={event?.settings?.registration?.transformation}
        color={event?.settings?.registration?.color}
        sx={{ typography: { md: 'h5', xs: 'h6' } }}>
        {array[0]}
        <Link
          to='/policy'
          style={{ color: event?.settings?.registration?.color }}>
          {array.length > 1 && array[1]}
        </Link>
        {array.length > 2 && array[2]}
      </Typography>
      <Stack alignItems='center' gap={2} mt={1}>
        <CustomButton
          isLoading={isLoading}
          onClick={handleNext}
          variant='contained'
          color='success'>
          next
        </CustomButton>
        <Button
          className={styles.roundBtn}
          onClick={() => {
            onReset();
            ref.current.clear();
          }}>
          reset
        </Button>
      </Stack>
    </Stack>
  );
}
