import {
  ErrorRounded,
  KeyboardHideRounded,
  KeyboardRounded,
} from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  CircularProgress,
  FormGroup,
  FormLabel,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemText,
  Paper,
  Popper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import propTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import Keyboard from 'react-simple-keyboard';
import styles from './styles/Components.module.css';

function CustomTextField({
  name,
  label,
  value,
  onInput,
  type = 'text',
  forEmail,
  focused,
  onFocus,
  error,
  transformation = 'capitalize',
  options = [],
  onSelect,
  searching = false,
  canSearch = true,
}) {
  const { event } = useSelector(state => state.log);
  const ref = React.useRef();
  const keyboardRef = React.useRef();
  const inputRef = React.useRef();
  const [layoutName, setLayoutName] = React.useState('default');
  const [start, setStart] = React.useState(0);
  const [open, setOpen] = React.useState(false);

  const onKeyPress = key => {
    if (key === '{shift}')
      setLayoutName(layoutName === 'default' ? 'shift' : 'default');
    if (key === '{lock}') setLayoutName('shift');
    if (key === '{bksp}') {
      value =
        value.substring(0, start - 1) + value.substring(start, value.length);
      onInput({ name, value });
      if (start > 0) setStart(start - 1);
    }
  };

  const onChange = input => {
    input = input.replace(/&gt;/g, '>');
    input = input.replace(/&lt;/g, '<');
    input = input.replace(/&amp;/g, '&');
    onInput({ name, value: input });
    setOpen(true);
  };

  const handleInput = e => {
    onInput({ name, value: e.target.value });
    keyboardRef.current.input[name] = e.target.value;
    setOpen(true);
  };

  const handleFocus = () => onFocus(focused === name ? '' : name);

  const handleMouseUpEvent = e => {
    if (
      typeof inputRef.current.selectionStart == 'number' &&
      typeof inputRef.current.selectionEnd == 'number'
    ) {
      setStart(inputRef.current.selectionEnd);
    }
  };

  React.useEffect(() => {
    let inputRefValue = null;

    if (inputRef.current) {
      inputRefValue = inputRef.current;
      inputRef.current.addEventListener('mouseup', handleMouseUpEvent);
    }

    return () => {
      if (inputRefValue)
        inputRefValue.removeEventListener('mouseup', handleMouseUpEvent);
    };
  }, [focused, name]);

  return (
    <React.Fragment>
      <Box
        component={Paper}
        elevation={0}
        ref={ref}
        sx={{ bgcolor: 'transparent' }}>
        <FormGroup>
          <FormLabel>
            <Typography
              textTransform={transformation}
              textAlign='start'
              fontWeight='bold'
              color={
                Boolean(error) ? 'error' : event?.settings?.registration?.color
              }
              sx={{ typography: { md: 'h4', xs: 'h6' } }}>
              {label}
            </Typography>
          </FormLabel>
          <Autocomplete
            open={canSearch && open}
            clearOnBlur={false}
            disablePortal={false}
            autoHighlight={false}
            value={{ [name]: value }}
            isOptionEqualToValue={(option, value) => option !== value}
            getOptionLabel={option => option[name]}
            noOptionsText={
              <Typography sx={{ typography: { md: 'h4', xs: 'h6' } }}>
                {searching
                  ? 'Loading...'
                  : value.length > 0
                  ? 'No search result found.'
                  : 'Waiting for input...'}
              </Typography>
            }
            renderOption={(props, option) => (
              <Paper
                {...props}
                py={1}
                key={option.user_id}
                onClick={() => {
                  onSelect(option);
                  setOpen(false);
                  onFocus('');
                }}>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography sx={{ typography: { md: 'h4', xs: 'h6' } }}>
                        {option.user_id}
                      </Typography>
                    }
                    secondary={
                      <Typography sx={{ typography: { md: 'h4', xs: 'h6' } }}>
                        {option.lastname}, {option.firstname}
                      </Typography>
                    }
                  />
                </ListItem>
              </Paper>
            )}
            options={options || []}
            PopperComponent={props => (
              <Popper
                {...props}
                placement='top-start'
                sx={{ maxHeight: { md: 350, xs: 250 }, overflow: 'hidden' }}
              />
            )}
            renderInput={params => (
              <TextField
                {...params}
                type={type}
                onFocus={() => {
                  onFocus(name);
                  setOpen(true);
                }}
                onBlur={() => setOpen(false)}
                name={name}
                value={value}
                onInput={handleInput}
                inputRef={inputRef}
                fullWidth
                error={Boolean(error)}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <InputAdornment position='end'>
                      {Boolean(error) && (
                        <ErrorRounded color='error' className={styles.icon} />
                      )}
                      {searching && name === focused && (
                        <CircularProgress size={32} />
                      )}
                      <IconButton onClick={handleFocus}>
                        {focused === name ? (
                          <KeyboardHideRounded className={styles.icon} />
                        ) : (
                          <KeyboardRounded className={styles.icon} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                  className: styles.input,
                }}
              />
            )}
          />
        </FormGroup>
        {Boolean(error) && (
          <Stack
            direction='row'
            gap={1}
            p={1}
            alignItems='center'
            sx={{ ...(focused === name && { bgcolor: '#ECECEC' }) }}>
            <Typography
              variant='h5'
              color='error'
              flex={1}
              fontWeight='bold'
              textAlign='left'>
              {error}
            </Typography>
          </Stack>
        )}
        {focused === name && (
          <Box className={styles.keyboardContainer}>
            <Keyboard
              keyboardRef={r => (keyboardRef.current = r)}
              layoutName={layoutName}
              onKeyPress={onKeyPress}
              onChange={onChange}
              inputName={name}
              layout={{
                default: [
                  '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
                  '{tab} q w e r t y u i o p [ ] \\',
                  "{lock} a s d f g h j k l ; ' {enter}",
                  '{shift} z x c v b n m , . / {shift}',
                  forEmail ? '@ .com {space}' : '{space}',
                ],
                shift: [
                  '~ ! @ # $ % ^ &amp; * ( ) _ + {bksp}',
                  '{tab} Q W E R T Y U I O P { } |',
                  '{lock} A S D F G H J K L : " {enter}',
                  '{shift} Z X C V B N M &lt; &gt; ? {shift}',
                  '{space}',
                ],
              }}
              buttonTheme={[
                {
                  class: styles.btnText,
                  buttons:
                    'Q W E R T Y U I O P A S D F G H J K L Z X C V B N M q w e r t y u i o p a s d f g h j k l z x c v b n m 1 2 3 4 5 6 7 8 9 0 - = [ ] ; : \' . , / @ ` \\ ~ ! @ # $ % ^ &amp; * ( ) _ + { } | " &lt; &gt; ?',
                },
                {
                  class: styles.btnSpec,
                  buttons: '{tab} {lock} {shift} .com {bksp} {enter}',
                },
              ]}
              display={{
                '{bksp}': 'Backspace',
                '{enter}': 'Enter',
                '{shift}': 'Shift',
                '{tab}': 'Tab',
                '{lock}': 'Caps Lock',
                '{space}': 'Space',
              }}
            />
          </Box>
        )}
      </Box>
    </React.Fragment>
  );
}

CustomTextField.propTypes = {
  label: propTypes.string.isRequired,
  value: propTypes.string.isRequired,
  onInput: propTypes.func.isRequired,
  transformation: propTypes.string,
  type: propTypes.string,
  forEmail: propTypes.bool,
  focused: propTypes.string.isRequired,
  onFocus: propTypes.func.isRequired,
  error: propTypes.string,
  options: propTypes.array,
  onSelect: propTypes.func,
  searching: propTypes.bool,
};

export default CustomTextField;
