import { ErrorRounded } from '@mui/icons-material';
import { Alert, Stack, Typography } from '@mui/material';
import axios from 'axios';
import React from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { SERVER_URL } from '../../assets/constants';
import CustomButton from '../../components/CustomButton';
import CustomTextField from '../../components/CustomTextField';
import { ACTIONS } from '../../states/log';

export default function Login() {
  const dispatch = useDispatch();
  const [data, setData] = React.useState({ email: '', password: '' });
  const [focus, setFocus] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [errors, setErrors] = React.useState(null);

  const handleInput = e => setData({ ...data, [e.name]: e.value });

  const handleFocus = e => setFocus(e);

  const handleSubmit = e => {
    e.preventDefault();
    setErrors(null);
    setIsLoading(true);
    axios
      .post(`${SERVER_URL}auth/sign-in`, data, { withCredentials: true })
      .then(({ data }) => {
        dispatch({ type: ACTIONS.ONLOGIN, payload: data });
        setIsLoading(false);
      })
      .catch(e => {
        if (e.response.status === 422) setErrors(e.response.data);
        if (e.response.status === 500)
          toast.error('An internal error occurred.');
        setIsLoading(false);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack px={4} gap={4}>
        {errors?.some(obj => obj.param === 'email') && (
          <Alert
            severity='error'
            icon={<ErrorRounded sx={{ fontSize: 42 }} />}
            sx={{ py: 3, px: 2 }}>
            <Typography variant='h4'>
              {errors?.find(obj => obj.param === 'email')?.msg}
            </Typography>
          </Alert>
        )}
        {errors?.some(obj => obj.param === 'password') && (
          <Alert
            severity='error'
            icon={<ErrorRounded sx={{ fontSize: 42 }} />}
            sx={{ py: 3, px: 2 }}>
            <Typography variant='h4'>
              {errors?.find(obj => obj.param === 'password')?.msg}
            </Typography>
          </Alert>
        )}
        <CustomTextField
          name='email'
          label='Email address'
          transformation='capitalize'
          forEmail
          focused={focus}
          value={data.email}
          onInput={handleInput}
          onFocus={handleFocus}
          canSearch={false}
        />
        <CustomTextField
          type='password'
          name='password'
          label='Password'
          transformation='capitalize'
          focused={focus}
          value={data.password}
          onInput={handleInput}
          onFocus={handleFocus}
          canSearch={false}
        />
        <CustomButton
          type='submit'
          color='primary'
          variant='contained'
          isLoading={isLoading}>
          sign in
        </CustomButton>
      </Stack>
    </form>
  );
}
