import { Button, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useSelector } from 'react-redux';
import CustomButton from '../../components/CustomButton';
import StepsContext from '../../context/StepsContext';
import styles from './styles/Steps.module.css';

export default function Step3() {
  const { event } = useSelector(state => state.log);
  const { data, onNext, onPrev } = React.useContext(StepsContext);

  var title = event?.settings?.step3?.title;
  if (title) {
    title = title.replace(/\*firstname\*/g, data.firstname);
    title = title.replace(/\*lastname\*/g, data.lastname);
    title = title.replace(/\*prcnumber\*/g, data.user_id);
  }

  var message = event?.settings?.step3?.message;
  if (message) {
    message = message.replace(/\*firstname\*/g, data.firstname);
    message = message.replace(/\*lastname\*/g, data.lastname);
    message = message.replace(/\*prcnumber\*/g, data.user_id);
  }

  return (
    <Stack gap={3} px={4} alignItems='center'>
      <Typography
        textTransform={event?.settings?.registration?.transformation}
        fontWeight='bold'
        color={event?.settings?.registration?.color}
        sx={{ typography: { md: 'h3', xs: 'h4' } }}>
        {title}
      </Typography>
      <Box className={styles.cameraContainer}>
        <img
          src={data.image && URL.createObjectURL(data.image)}
          alt='Camera screenshot'
          className={styles.screenshot}
        />
      </Box>

      <img
        src={data.signature && URL.createObjectURL(data.signature)}
        alt='Signature'
        className={styles.signature}
      />

      <Typography
        textTransform={event?.settings?.registration?.transformation}
        color={event?.settings?.registration?.color}
        sx={{ typography: { md: 'h4', xs: 'h6' } }}>
        {message}
      </Typography>

      <CustomButton
        variant='contained'
        color='success'
        className={styles.roundBtn}
        onClick={onNext}>
        continue
      </CustomButton>
      <Button className={styles.roundBtn} onClick={onPrev}>
        go back
      </Button>
    </Stack>
  );
}
