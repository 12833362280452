import React from 'react';
import Step1 from './Step1';
import { Carousel } from 'react-responsive-carousel';
import StepsContext from '../../context/StepsContext';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';

const INITIAL_STATE = {
  data: {
    user_id: '',
    firstname: '',
    lastname: '',
    isReckitt: false,
    signature: null,
    image: null,
  },
  step: 0,
};

const ACTIONS = {
  HANDLEONINPUT: 'HANDLEONINPUT',
  HANDLEONNEXT: 'HANDLEONNEXT',
  HANDLEONPREV: 'HANDLEONPREV',
  HANDLEONRESET: 'HANDLEONRESET',
  HANDLEONFULLRESET: 'HANDLEONFULLRESET',
  HANDLEONSELECT: 'HANDLEONSELECT',
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.HANDLEONINPUT:
      return {
        ...state,
        data: { ...state.data, [action.key]: action.payload },
      };
    case ACTIONS.HANDLEONNEXT:
      return { ...state, step: state.step + 1 };
    case ACTIONS.HANDLEONPREV:
      return { ...state, step: state.step - 1 };
    case ACTIONS.HANDLEONSELECT:
      return { ...state, data: { ...state.data, ...action.payload } };
    case ACTIONS.HANDLEONRESET:
      return {
        ...state,
        data: {
          ...state.data,
          user_id: '',
          firstname: '',
          lastname: '',
          isReckitt: false,
          signature: null,
        },
      };
    case ACTIONS.HANDLEONFULLRESET:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default function Steps() {
  const [state, dispatch] = React.useReducer(reducer, INITIAL_STATE);
  const [reset, setReset] = React.useState(false);

  const handleOnInput = (key, payload) =>
    dispatch({ type: ACTIONS.HANDLEONINPUT, key, payload });

  const handleNext = () => {
    dispatch({ type: ACTIONS.HANDLEONNEXT });
    document.body.focus();
  };

  const handlePrev = () => dispatch({ type: ACTIONS.HANDLEONPREV });

  const handleReset = () => dispatch({ type: ACTIONS.HANDLEONRESET });

  const handleFullReset = () => {
    dispatch({ type: ACTIONS.HANDLEONFULLRESET });
    setReset(true);
  };

  const handleSelect = e =>
    dispatch({ type: ACTIONS.HANDLEONSELECT, payload: e });

  return (
    <StepsContext.Provider
      value={{
        step: state.step,
        data: state.data,
        onInput: handleOnInput,
        onNext: handleNext,
        onPrev: handlePrev,
        onSelect: handleSelect,
        onReset: handleReset,
        onFullReset: handleFullReset,
      }}>
      <Carousel
        showThumbs={false}
        swipeable={false}
        autoPlay={false}
        showArrows={false}
        centerMode={false}
        selectedItem={state.step}
        showIndicators={false}
        showStatus={false}
        axis='horizontal'>
        <Step1 reset={reset} onFinish={setReset} />
        <Step2 />
        <Step3 />
        <Step4 />
      </Carousel>
    </StepsContext.Provider>
  );
}
