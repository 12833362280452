import {
  EventRepeatRounded,
  NotListedLocationRounded,
  SettingsRounded,
} from '@mui/icons-material';
import {
  Box,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  Typography,
} from '@mui/material';
import React from 'react';
import styles from './styles/Location.module.css';
import Logout from './Logout';
import Cookies from 'universal-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { ACTIONS } from '../../states/log';

export default function Settings() {
  const { isLoggedIn, event, location } = useSelector(state => state.log);
  const dispatch = useDispatch();
  const cookie = new Cookies();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleOpen = e => setAnchorEl(e.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleEventReset = () => {
    cookie.remove('marshalSession', {
      domain:
        process.env.NODE_ENV === 'production' ? 'cdeventshub.com' : 'localhost',
    });
    cookie.remove('locationSession', {
      domain:
        process.env.NODE_ENV === 'production' ? 'cdeventshub.com' : 'localhost',
    });
    dispatch({ type: ACTIONS.ONRESETEVENT });
    handleClose();
  };

  const handleLocationReset = () => {
    cookie.remove('locationSession', {
      domain:
        process.env.NODE_ENV === 'production' ? 'cdeventshub.com' : 'localhost',
    });
    dispatch({ type: ACTIONS.ONRESETLOCATION });
    handleClose();
  };

  return (
    <Box className={styles.container}>
      <IconButton onClick={handleOpen}>
        <SettingsRounded
          sx={{
            fontSize: 42,
            color: event?.settings?.registration?.color || 'black',
          }}
        />
      </IconButton>

      <Menu
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleClose}>
        {Boolean(event) && (
          <ListItemButton onClick={handleEventReset}>
            <ListItemIcon>
              <EventRepeatRounded />
            </ListItemIcon>
            <ListItemText
              primary={<Typography variant='h4'>Change Event</Typography>}
            />
          </ListItemButton>
        )}
        {Boolean(location) && (
          <ListItemButton onClick={handleLocationReset}>
            <ListItemIcon>
              <NotListedLocationRounded />
            </ListItemIcon>
            <ListItemText
              primary={<Typography variant='h4'>Change Location</Typography>}
            />
          </ListItemButton>
        )}
        {Boolean(isLoggedIn) && <Logout onClose={handleClose} />}
      </Menu>
    </Box>
  );
}
