import { Stack, Typography } from '@mui/material';
import styles from './App.module.css';
import './App.css';
import Steps from './views/registration/Steps';
import { useDispatch, useSelector } from 'react-redux';
import Logo from './images/logo.png';
import { Navigate, Route, Routes } from 'react-router-dom';
import Login from './views/auth/Login';
import { toast, ToastContainer } from 'react-toastify';
import { ErrorRounded } from '@mui/icons-material';
import EventSelection from './views/selection/EventSelection';
import React from 'react';
import axios from 'axios';
import { FILE_URL, SERVER_URL } from './assets/constants';
import { ACTIONS } from './states/log';
import { RotatingLines } from 'react-loader-spinner';
import Cookies from 'universal-cookie';
import Policy from './views/policy/Policy';
import Settings from './views/location/Settings';
import Venue from './views/location/Venue';

function App() {
  const { isLoggedIn, event } = useSelector(state => state.log);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(true);
  const cookie = new Cookies();

  if (event) {
    document.title = event?.name;
  }

  React.useLayoutEffect(() => {
    (() => {
      setIsLoading(true);
      axios
        .get(`${SERVER_URL}auth/get-session`, { withCredentials: true })
        .then(data => {
          if (data.status === 204) dispatch({ type: ACTIONS.ONLOGOUT });
          if (data.status === 200)
            dispatch({ type: ACTIONS.ONLOGIN, payload: data.data.admin });
          if (data.data.event)
            dispatch({
              type: ACTIONS.ONEVENTCHANGE,
              payload: data.data.event,
            });
          if (data.data.location)
            dispatch({
              type: ACTIONS.ONLOCATIONCHANGE,
              payload: data.data.location,
            });
          setIsLoading(false);
        })
        .catch(e => {
          toast.error('An internal error occurred.');
          setIsLoading(false);
        });
    })();
  }, [dispatch]);

  return isLoading ? (
    <Stack className={styles.loading}>
      <Stack gap={4} alignItems='center'>
        <RotatingLines
          strokeColor='black'
          strokeWidth='5'
          animationDuration='0.75'
          width='180'
          visible={true}
        />
        <Typography variant='h4'>Checking sessions...</Typography>
      </Stack>
    </Stack>
  ) : (
    <Stack
      sx={{
        background:
          event?.settings?.app?.background?.datatype === 'color' &&
          event?.settings?.app?.background?.data,
        ...(event?.settings?.app?.background?.datatype === 'image' && {
          '&:before': {
            content: "''",
            position: 'fixed',
            background: `url(${FILE_URL}${event?.settings?.app?.background?.data}) no-repeat center fixed`,
            backgroundSize: 'cover',
            inset: 0,
            zIndex: -10,
            opacity: 0.95,
          },
        }),
      }}>
      <Stack className={styles.container}>
        <ToastContainer
          autoClose={2000}
          pauseOnFocusLoss={false}
          pauseOnHover={false}
          closeButton={false}
          className={styles.toastContainer}
          toastClassName={styles.toast}
          bodyClassName={styles.toastBody}
          icon={<ErrorRounded color='error' className={styles.errorIcon} />}
        />
        <Settings />
        <Venue />
        <Stack alignItems='center' width='100%'>
          <img
            src={
              event?.settings?.app?.logo &&
              event?.settings?.app?.logo !== 'no image'
                ? `${FILE_URL}${event?.settings?.app?.logo}`
                : Logo
            }
            alt='Logo'
            className={styles.logo}
          />
          <Typography
            className={styles.title}
            textTransform={event?.settings?.registration?.transformation}
            color={event?.settings?.registration?.color}
            fontWeight='bold'>
            {event?.settings?.registration?.title
              ? event?.settings?.registration?.title
              : 'CJ EVENT HUB'}
          </Typography>
        </Stack>
        <Stack mt={2} flex={1}>
          <Routes>
            <Route
              element={
                isLoggedIn ? (
                  !cookie.get('marshalSession') ? (
                    <EventSelection />
                  ) : (
                    <Navigate to={`/${event.name}`} />
                  )
                ) : (
                  <Login />
                )
              }
              path='/'
            />
            <Route element={<Policy />} path='/policy' />
            <Route
              element={
                cookie.get('marshalSession') ? (
                  isLoggedIn ? (
                    <Steps />
                  ) : (
                    <Navigate to='/' />
                  )
                ) : (
                  <Navigate to='/' />
                )
              }
              path='/:name'
            />
          </Routes>
        </Stack>
        {event && (
          <Stack>
            {event?.settings?.app?.footer?.datatype === 'none' ? null : event
                ?.settings?.app?.footer?.datatype === 'text' ? (
              <Typography
                sx={{ typography: { md: 'h4', xs: 'h5' }, textAlign: 'center' }}
                dangerouslySetInnerHTML={{
                  __html: event?.settings?.app?.footer?.data,
                }}
              />
            ) : (
              <img
                src={`${FILE_URL}${event?.settings?.app?.footer?.data}`}
                alt='footer'
                className={styles.footer}
              />
            )}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}

export default App;
