import {
  Alert,
  Card,
  CardContent,
  CardMedia,
  Skeleton,
  Stack,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import axios from 'axios';
import React from 'react';
import { toast } from 'react-toastify';
import { FILE_URL, SERVER_URL } from '../../assets/constants';
import styles from './styles/Selection.module.css';
import Cookies from 'universal-cookie';
import { useDispatch } from 'react-redux';
import { ACTIONS } from '../../states/log';
import { useNavigate } from 'react-router-dom';

export default function EventSelection() {
  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useLayoutEffect(() => {
    axios
      .get(`${SERVER_URL}events/selection`, { withCredentials: true })
      .then(({ data }) => {
        setData(data);
        setIsLoading(false);
      })
      .catch(e => {
        toast.error('An internal error occurred.');
      });
  }, []);

  return (
    <Stack gap={2}>
      <Typography variant='h2' textAlign='center'>
        Select an event
      </Typography>
      <Grid
        container
        spacing={1}
        px={8}
        gridAutoRows='1fr'
        justifyContent='center'>
        {isLoading ? (
          [1, 2, 3, 4].map(val => (
            <Grid md={6} key={val}>
              <Card className={styles.card}>
                <Skeleton variant='rounded' height={240}></Skeleton>
                <CardContent>
                  <Skeleton>
                    <Typography variant='h4'>Lorem ipsum dolor</Typography>
                  </Skeleton>
                  <Skeleton>
                    <Typography variant='h5'>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Beatae aspernatur sed consectetur mollitia nulla delectus
                    </Typography>
                  </Skeleton>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : data.length > 0 ? (
          data.map(val => <Cards key={val._id} data={val} />)
        ) : (
          <Alert severity='info' icon={false}>
            <Typography variant='h2'>No ongoing events</Typography>
          </Alert>
        )}
      </Grid>
    </Stack>
  );
}

function Cards({ data }) {
  const cookie = new Cookies();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = () => {
    cookie.set('marshalSession', data._id, {
      domain:
        process.env.NODE_ENV === 'production' ? 'cjeventhub.com' : 'localhost',
    });
    dispatch({ type: ACTIONS.ONEVENTCHANGE, payload: data });
    navigate(`/${data.name}`);
  };

  return (
    <Grid md={6}>
      <Card className={styles.card} onClick={handleClick}>
        <CardMedia
          image={`${FILE_URL}${data.image}`}
          className={styles.cardImage}
          title={data.name}
        />
        <CardContent>
          <Typography variant='h4'>{data.name}</Typography>
          <Typography variant='h5'>
            {data.description ? data.description : 'No description provided.'}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}
