const INITIAL_STATE = {
  isLoggedIn: false,
  user: null,
  event: null,
  location: null,
};

const ACTIONS = {
  ONLOGIN: 'ONLOGIN',
  ONLOGOUT: 'ONLOGOUT',
  ONEVENTCHANGE: 'ONEVENTCHANGE',
  ONLOCATIONCHANGE: 'ONLOCATIONCHANGE',
  ONRESETEVENT: 'ONRESETEVENT',
  ONRESETLOCATION: 'ONRESETLOCATION',
};

const LogReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.ONLOGIN:
      return { ...state, isLoggedIn: true, user: action.payload };
    case ACTIONS.ONLOGOUT:
      return INITIAL_STATE;
    case ACTIONS.ONEVENTCHANGE:
      return { ...state, event: action.payload };
    case ACTIONS.ONRESETEVENT:
      return { ...state, event: null, location: null };
    case ACTIONS.ONLOCATIONCHANGE:
      return { ...state, location: action.payload };
    case ACTIONS.ONRESETLOCATION:
      return { ...state, location: null };
    default:
      return state;
  }
};

export { ACTIONS };

export default LogReducer;
