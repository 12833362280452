import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Stack,
  Typography,
} from '@mui/material';
import axios from 'axios';
import React from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { SERVER_URL } from '../../assets/constants';
import StepsContext from '../../context/StepsContext';
import styles from './styles/Steps.module.css';

export default function Step4() {
  const { event, location } = useSelector(state => state.log);
  const { step, data, onFullReset } = React.useContext(StepsContext);
  const ref = React.useRef('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  var title = event?.settings?.step4?.title;
  if (title) {
    title = title.replace(/\*firstname\*/g, data.firstname);
    title = title.replace(/\*lastname\*/g, data.lastname);
    title = title.replace(/\*prcnumber\*/g, data.user_id);
  }

  var message = event?.settings?.step4?.message;
  if (message) {
    message = message.replace(/\*firstname\*/g, data.firstname);
    message = message.replace(/\*lastname\*/g, data.lastname);
    message = message.replace(/\*prcnumber\*/g, data.user_id);
  }

  const handleClose = () => {
    onFullReset();
    setOpen(false);
  };

  const handleSubmit = React.useCallback(() => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('image', data.image);
    formData.append('signature', data.signature);
    formData.append('user_id', data.user_id);
    formData.append('firstname', data.firstname);
    formData.append('lastname', data.lastname);
    formData.append('isDoctor', !data.isReckitt);
    formData.append('qrcode', ref.current);
    formData.append('location', location._id);
    formData.append('event', event._id);
    axios
      .post(`${SERVER_URL}registration/create-user-data`, formData, {
        withCredentials: true,
      })
      .then(data => {
        ref.current = '';
        setIsLoading(false);
        setOpen(true);
      })
      .catch(e => {
        if (e.response.status === 400)
          e.response.data.map(obj => toast.error(obj.msg));
        if (e.response.status === 422)
          Object.keys(e.response.data).map(key =>
            toast.error(e.response.data[key].message)
          );
        ref.current = '';
        setIsLoading(false);
      });
  }, [data, location, event]);

  const handleEvent = React.useCallback(
    ev => {
      if (step !== 3) return;
      const notAllowed =
        /Shift|Tab|Enter|Alt|Control|Meta|CapsLock|Escape|F1|F2|F3|F4|F5|F6|F7|F8|F9|F10|F11|F12|Backspace|NumLock|ScrollLock|Pause|Insert|Home|PageUp|Delete|End|PageDown|ArrowUp|ArrowDown|ArrowLeft|ArrowRight/;
      if (!notAllowed.test(ev.key)) {
        ref.current += ev.key;
      }
      if (ev.key === 'Enter') {
        handleSubmit();
      }
    },
    [handleSubmit, step]
  );

  React.useEffect(() => {
    if (step === 3) {
      document.addEventListener('keydown', handleEvent);
    }
    return () => {
      document.removeEventListener('keydown', handleEvent);
    };
  }, [handleEvent, step]);

  return (
    <Stack gap={4} px={4}>
      <Typography
        fontWeight='bold'
        textTransform={event?.settings?.registration?.transformation}
        color={event?.settings?.registration?.color}
        sx={{ typography: { md: 'h3', xs: 'h4' } }}>
        {title}
      </Typography>
      <Box className={styles.cameraContainer}>
        <img
          src={data.image && URL.createObjectURL(data.image)}
          alt='Screenshot'
          className={styles.screenshot}
        />
      </Box>
      {event?.settings?.step4?.display && (
        <img
          src={data.signature && URL.createObjectURL(data.signature)}
          alt='Signature'
          className={styles.signature}
        />
      )}
      <Typography
        textTransform={event?.settings?.registration?.transformation}
        color={event?.settings?.registration?.color}
        sx={{ typography: { md: 'h4', xs: 'h6' } }}>
        {message}
      </Typography>

      <Dialog
        open={open}
        PaperProps={{
          sx: { backgroundColor: 'transparent', boxShadow: 'none' },
        }}>
        <DialogContent
          sx={{
            color: event?.settings?.final?.color,
            borderRadius: 8,
            bgcolor: `${event?.settings?.final?.bgcolor}`,
            backdropFilter: `blur(${event?.settings?.final?.blur}px)`,
            WebkitBackdropFilter: `blur(${event?.settings?.final?.blur}px)`,
            py: 8,
            mb: 2,
          }}>
          <Stack gap={4}>
            <Typography
              textAlign='center'
              sx={{ typography: { md: 'h3', xs: 'h4' } }}>
              {event?.settings?.final?.title}
            </Typography>
            <Typography
              textAlign='center'
              sx={{ typography: { md: 'h4', xs: 'h5' } }}>
              {event?.settings?.final?.message}
            </Typography>
          </Stack>
        </DialogContent>
        <Button
          className={styles.roundBtn}
          variant='contained'
          sx={{
            width: 'fit-content',
            bgcolor: `${event?.settings?.final?.bgcolor}`,
            color: event?.settings?.final?.color,
            backdropFilter: `blur(${event?.settings?.final?.blur}px)`,
            WebkitBackdropFilter: `blur(${event?.settings?.final?.blur}px)`,
            mx: 'auto',
            '&:hover': {
              bgcolor: event?.settings?.final?.bgcolor,
            },
          }}
          onClick={handleClose}>
          ok
        </Button>
      </Dialog>

      <Dialog
        open={isLoading}
        PaperProps={{
          sx: {
            overflow: 'hidden',
            backgroundColor: '#fff7',
            boxShadow: 'none',
            p: 4,
            borderRadius: 2,
          },
        }}>
        <Stack alignItems='center' gap={2}>
          <CircularProgress size='65%' sx={{ color: '#000' }} />
          <Typography
            fontWeight='bold'
            sx={{ typography: { md: 'h3', xs: 'h4' } }}>
            Creating data...
          </Typography>
        </Stack>
      </Dialog>
    </Stack>
  );
}
